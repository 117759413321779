.main-sidebar {
	width:  280px;
	transition: width 0.15s;
}

.main-sidebar.collapsed {
	width:  4.5rem;
}

.main-sidebar .dropdown {
	position: inherit;
}
