.h3, h3
    font-weight: 500
    line-height: 1.2
    margin-bottom: 0.5rem
    margin-top: 0


@for $i from 1 through 5
  $zIndexBackdrop: #{1000 + (5 * $i)}
  $zIndexContent: #{1000 + (5 * $i) + 2}

  .modal-backdrop.show:nth-of-type(#{$i})
    z-index: $zIndexBackdrop

  div[role="dialog"][aria-modal="true"]:nth-of-type(#{$i})
    z-index: $zIndexContent

.w-330
  width: 330px
  max-width: 330px
  min-width: 330px

.mw-330
  max-width: 330px


.minw-100
  min-width: 100%

.h-500px
  height: 500px

.navbar

  &.border-dark
    border-color: #325ee9 !important

.shape:not([class*=shape-blur])>*
    -webkit-transform: scale(2)
    transform: scale(2)

.shape:not([class*=shape-blur])
    overflow: hidden


.shape-bottom
    right: 0
    bottom: 0
    left: 0

.shape-top
    top: 0
    right: 0
    left: 0

.shape-bottom>*
    -webkit-transform-origin: top center
    transform-origin: top center

.shape>*
    display: block

.shape
    position: absolute
    pointer-events: none

    &.text-dark
      color: #1b2a4e !important

    svg
      overflow: hidden
      vertical-align: middle

.shape:not([class*=shape-blur])>*
    -webkit-transform: scale(2)
    transform: scale(2)

.shape:not([class*=shape-blur])
    overflow: hidden

.shape-fluid-x>*
    width: 100%
    height: auto

.footer-parent

  &.bg-dark
    background-color: #1b2a4e !important


.nav-item-shrink
  a
    padding: 0.5rem 0rem 0.5rem 1rem

  .dropdown-menu
    border-radius: 0

.card
  .shadowed-details
    text-shadow: 0 0 5px black
    color: #fff

    svg
      filter: drop-shadow(1px 1px 1px rgb(0, 0, 0))

  &.transparent
    opacity: 0.5
    transition: opacity 1s

    &:hover
      opacity: 1


.text-justify
  text-align: justify


.private-meta-nav
  color: #edc893

  a
    color: var(--bs-gray-600)
    text-decoration: none

  a:hover
    text-decoration: underline

  a.active
    color: var(--bs-body-color)
    text-decoration: none

.dropdown-no-toggle::after
  display: none !important

.navdropdown-no-toggle
  .dropdown-toggle::after
    display: none !important


.text-body-bg
  color: var(--bs-body-bg)