.arrow
  pointer-events: none
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))

.arrow__path
  stroke: #fff
  stroke-width: 2px
  box-shadow: 1px 1px 1px #000
  fill: transparent
  stroke-dasharray: 4 2

.arrow__head line
  stroke: #fff
  stroke-width: 2px

.arrow__msg
  margin: 100px 150px 0 0
  padding: 2px 10px
  max-width: 150px
