html[data-bs-theme='dark']
    .react-select-container
        .react-select__control
            background-color: var(--bs-body-bg) !important
            border: var(--bs-border-width) solid var(--bs-border-color)

        .react-select__placeholder
            color: #d1d5db !important

        .react-select__single-value
            color: var(--bs-body-color)

        .react-select__multi-value
            background-color: slategray
            color: var(--bs-body-color)

        .react-select__input-container
            color: #d1d5db
            border: none

        .react-select__input-container:focus
            border: none !important
            outline: none !important

        .react-select__input:focus
            border: none !important
            outline: none !important

        .react-select__input::after
            border: none !important
            display: none

        .react-select__multi-value__label
            color: var(--bs-body-color)

        .react-select__menu
            background-color: #2d3748

        .react-select__option
            color: var(--bs-body-color)

        .react-select__option--is-focused
            background-color: slategray
