$sidebar-bg-color: #0c1e35
$submenu-bg-color: rgba(255, 255, 255, 0.05)
$submenu-bg-color-collapsed: rgba(#0c1e35, 0.95)
$sidebar-color: #b3b8d4
$highlight-color: #dee2ec
$icon-bg-color: rgba(255, 255, 255, 0.05)
$icon-size: 35px
$ci-secondary-color: #62656e


body
  margin: 0
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

html
  height: 100%

body
  margin: 0
  height: 100%

main
  display: flex
  flex-wrap: nowrap
  height: 100vh
  height: -webkit-fill-available
  max-height: 100vh
  overflow-x: auto
  overflow-y: hidden

.main-sidebar + div
  overflow-x: auto
  overflow-y: auto

.user-select-none
  -moz-user-select: none
  -webkit-user-select: none
  -ms-user-select: none
  -o-user-select: none
  user-select: none

.fg-ci-secondary
  color: $ci-secondary-color

.text-ci-secondary-sub-actions
  button, a
    color: #b7b7b7 !important

.text-light-sub-actions
  button, a
    color: #aaaaaa !important

.bg-ci-secondary
  background-color: $ci-secondary-color

.glassed

  // background-image: url(../img/bg/app.jpg)
  background-position: center
  background-size: cover

  .main-sidebar + div
    background: var(--bs-secondary-bg-subtle)!important
    backdrop-filter: blur(19.7px)
    -webkit-backdrop-filter: blur(19.7px)

#root
  height: 100%

.ratio-1
  aspect-ratio: 1 / 1

.app
  height: 100%
  display: flex
  position: relative

  .module
    position: absolute
    left: 0
    right: 0
    bottom: 0
    top: 0
    display: flex
    flex-direction: column

    > h2
      margin-bottom: 0
      user-select: none

    .mainspace
      flex: 1
      overflow-y: auto

      .card-warehouse
        min-height: 50px
        box-shadow: 0 0 0.875rem 0 rgb(41 48 66 / 5%)

        &:hover
          .card-body
            color: #fff !important
            background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important

      .splitter
        display: flex
        position: relative
        height: 100%

        .panel
          overflow-y: scroll

  .frame
    padding: 2rem

  .btn-toggle
    cursor: pointer
    width: 35px
    height: 35px
    background: #353535
    color: #fff
    text-align: center
    border-radius: 50%
    display: flex
    align-items: center
    justify-content: center
    font-size: 15px
    display: none

  .sidebar-btn-wrapper
    display: flex
    align-items: center
    justify-content: center

    .sidebar-btn
      width: 150px
      padding: 1px 15px
      border-radius: 40px
      background: rgba(255, 255, 255, 0.05)
      color: #adadad
      text-decoration: none
      margin: 0 auto
      height: 35px
      display: flex
      align-items: center
      justify-content: center
      text-overflow: ellipsis
      overflow: hidden

      span
        margin-left: 5px
        font-size: 13px

      &:hover
        color: $highlight-color

  .collapsed
    .sidebar-btn
      display: inline-block
      padding: 0

  main
    padding: 24px
    flex-grow: 1
    display: flex
    flex-direction: column
    overflow-y: auto

    header
      margin-bottom: 40px
      border-bottom: 1px solid #efefef

      h1
        display: flex
        align-items: center
        transform: translateX(-20px)

    footer
      color: #888
      text-align: center

  .block
    display: flex
    margin-bottom: 24px
    color: #545454
    > span
      margin-left: 10px


.badger
  padding: 3px 6px
  font-size: 9px
  letter-spacing: 1px
  border-radius: 15px

  &.red
    color: #ffffff
    background: #d63030

  &.gray
    color: #ffffff
    background: #777777

  &.yellow
    color: #000000
    background: #ffd331

.card
    &.collection
      &:hover
        box-shadow: 0px 0px 4px 0px #9c9c9c !important

.breadcrumb-item
  a
    text-decoration: none

  &.active
    color: var(--bs-body-color)

nav
  ol.breadcrumb
    margin-bottom: 0


.center-hint
  text-align: center
  user-select: none


.drag-active
  box-shadow: inset 0px 0px 6px 2px #9c9c9c


.react-select.rounded-start-0
  div
    div
      border-top-left-radius: 0
      border-bottom-left-radius: 0


.my-masonry-grid
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  margin-left: -30px
  width: auto


.my-masonry-grid_column
  padding-left: 30px
  background-clip: padding-box


.my-masonry-grid_column > div
  margin-bottom: 30px


.bg-cover
    background: no-repeat center center/cover

.footer-stream
    a
      color: var(--bs-body-color)

    .active
      color: var(--bs-gray-600)

.text-pre-wrap
  white-space: pre-wrap

.cursor-pointer
  cursor: pointer

iframe
  color-scheme: initial
