.main-sidebar

    overflow: scroll
    overflow-x: hidden
    z-index: 1

    &::-webkit-scrollbar
        width: 0
        background: transparent

    &::-webkit-scrollbar-thumb
        background: #FF0000

    .bi
        vertical-align: -.125em
        pointer-events: none
        fill: currentColor

    .nav-flush
        .nav-link
            border-radius: 0

    .nav-link-expanded
        color: var(--bs-body-color)

    svg
        transition: width 0.15s, height 0.15s

    ul
        li
            a
                &:hover
                    color: $white
                    background-color: $primary !important

.b-example-divider
    flex-shrink: 0
    width: 1.25rem
    height: 100vh
    background-color: rgba(0, 0, 0, .1)
    border: solid rgba(0, 0, 0, .15)
    border-width: 1px 0
    box-shadow: inset 0 0.5em 1.5em rgb(0 0 0 / 10%), inset 0 0.125em 0.5em rgb(0 0 0 / 15%)
    background-image: url(../img/bg/wa.png)
